"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const appConfig = {
    theme: {
        dark: false,
    },
    appName: 'マチゼロ',
    organizationId: 'shokufesnLG1sOTbvaLG',
    version: '1.0',
    ionicAppId: '',
    applicationKey: 'machizero',
    applicationType: 'organization',
    artistId: undefined,
    languages: ['ja', 'en'],
    ios: {
        bundleId: 'jp.machizero',
        appId: '',
    },
    android: {
        bundleId: 'jp.machizero',
    },
    platform: ['web'],
    custom: {
        modules: {
            tutorial: false,
            trade: false,
            membership: false,
            thread: false,
            prepaidPoint: null,
            gacha: false,
            nft: false,
            liveStream: false,
            item: true,
            store: true,
            campaign: false,
            mission: false,
            resale: true,
            preSale: true,
            event: true,
            cart: true,
            pickup: true,
            eprint: false,
            konbiniPayment: true,
        },
        tutorials: [
            'images/tutorial/Tutorial01.jpg',
            'images/tutorial/Tutorial02.jpg',
            'images/tutorial/Tutorial03.jpg',
        ],
        globalTabs: ['home', 'events', 'pickup', 'myProfile'],
        artistTabs: ['home', 'store', 'posts'],
    },
    deeplinkUrl: 'https://app.machizero.jp',
    helpUrl: 'https://help.utoniq.com/ja/collections/6304346',
    storagePath: 'shokufes',
    dynamicLinkPrefix: 'shokufes',
    deeplinkProd: 'app.machizero.jp',
};
exports.default = appConfig;
